@keyframes oscillate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(30deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}
