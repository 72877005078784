@font-face {
  font-family: "Monument Extended Bold";
  src: url("font/55d433372d270829c51e2577a78ef12d.eot"); /* IE9*/
  src: url("font/55d433372d270829c51e2577a78ef12d.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("font/55d433372d270829c51e2577a78ef12d.woff2")
      format("woff2"),
    /* chrome、firefox */ url("font/55d433372d270829c51e2577a78ef12d.woff")
      format("woff"),
    /* chrome、firefox */ url("font/55d433372d270829c51e2577a78ef12d.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("font/55d433372d270829c51e2577a78ef12d.svg#Monument Extended Bold")
      format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "Monument Extended Ultrabold";
  src: url("font/ultrabold/a4745f4b1d04fa025c6a05d891ed0126.eot"); /* IE9*/
  src: url("font/ultrabold/a4745f4b1d04fa025c6a05d891ed0126.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("font/ultrabold/a4745f4b1d04fa025c6a05d891ed0126.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("font/ultrabold/a4745f4b1d04fa025c6a05d891ed0126.woff") format("woff"),
    /* chrome、firefox */
      url("font/ultrabold/a4745f4b1d04fa025c6a05d891ed0126.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("font/ultrabold/a4745f4b1d04fa025c6a05d891ed0126.svg#Monument Extended Ultrabold")
      format("svg"); /* iOS 4.1- */
}
html,
body {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Poppins", sans-serif;
}

.testBorder {
  border: 2px solid rgb(250, 82, 82, 0.3);
}
