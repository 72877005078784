.projectsSlider {
  display: flex;
  height: 100%;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
}

.projectsSlider .projSlider1 {
  width: 47%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.projectsSlider .projSlider2 {
  width: 47%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.projectsSlider .projSlider1 img {
  width: 100%;
  border-radius: 20px;
  object-fit: contain;
}
.projectsSlider .projSlider2 img {
  width: 100%;
  border-radius: 20px;
  object-fit: contain;
}

.overlay-container {
  position: relative;
}
.overlay-text {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  position: absolute;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  /* align-items: center; */
  justify-content: center;
}

.projectsPrevSlider {
  width: 70%;
  /* margin-left: 6%; */
  position: relative;
  /* height: 100%; */
}
.projectsPrevCarousel {
  width: 50rem;
}
.projectsPrevCarousel img {
  max-width: 98.8%;
  object-fit: contain;
}

.custom-pagination {
  margin-left: 42.5%;
}

.custom-pagination .swiper-pagination-bullet {
  margin: 0 !important;
  width: 30px; /* Adjust the width of the dots */
  height: 5px;
  border-radius: 0;
  background-color: rgb(248, 247, 247);
  transition: all 1.5s ease;
}

.projectsPrevSlider .swiper-button-prev {
  position: absolute;
  left: -12%;
  width: 4rem;
  height: auto;
}

.projectsPrevSlider .swiper-button-next {
  position: absolute;
  right: -12%;
  width: 4rem;
  height: auto;
}
