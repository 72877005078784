.footerMain {

  margin-left: 6%;
  padding-bottom: 3rem;
  padding-top: 5rem;
  padding: 5rem 3rem 3rem 3rem;
  background-color: #000;
  position: relative;
  z-index: 1;
}

/* .logoDiv {

  display: flex;
  justify-content: center;
} */

.addressDiv {
  padding-top:1rem;
  width: 28%;
  display: flex;
  flex-direction: column;
}

.contactDiv {
  width: 16%;
  display: flex;
  flex-direction: column;
}

.navLinksDiv {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addressDiv p,
.contactDiv p,
.contactDiv a,
.navLinksDiv a,
.socialLinks a {
  margin: 0;
  color: #fcddec;
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* text-align: center; */
  /* width: 80%; */
  line-height: 1.6;
  letter-spacing: 1px;
}
.contactDiv h2,
.addressDiv h2 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 2px;
  color: #fcddec;
  margin-bottom: 1.2rem;
}
/* .logoDiv img {
  margin-top: -3rem;
  object-fit: contain;

} */
.socialLinks {
  /* width: 15%; */
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
}
.socialLinks a,
.navLinksDiv a {
  margin-bottom: 1rem;
}
